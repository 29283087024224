import styled, { css } from 'styled-components';

import { container, offset, columnsGutter } from 'styles/grid';
import { minWidth, minMax, maxWidth } from 'styles/media-queries';
import { getModifierStyles } from 'utils/get-modifier-styles';
import { minBreakpoints } from 'styles/variables';
import {
  fadeContentSlideUp,
  ACTIVATE_ANIMATION_CLASS,
} from 'styles/animations';
import { multiple } from 'components/atoms/cta/Cta.styled';
import { Cta as CtaComponent } from 'components/atoms/cta';
import { Paragraph } from 'components/atoms/paragraph';
import { Headline } from 'components/atoms/headline';
import { spacer2, spacer3, spacer4 } from 'styles/spacers';
import { black, gray, white } from 'styles/colors';

/* Buffer */
const buffer = {
  sm: 40,
  lg: 80,
};

// Variation references https://huge.invisionapp.com/d/main#/console/13945729/290585045/preview
export const MODIFIERS = {
  useA: 'useA', // text left, image right
  useB: 'useB', // image right, text left
  useC: 'useC', // text left, module bottom
  useD: 'useD', // text center, module bottom
  useE: 'useE', // text left, image top
  useF: 'useF', // text left, video right
  useG: 'useG', // text left, image bottom
  useH: 'useH', // text only centered
};

const ROOT_A = css`
  background-color: ${props => props.backgroundColor || black};
  color: ${props => props.textColor || white};
  ${minWidth.lg`
    margin-bottom: 6rem;
   `}
`;

const ROOT_B = css`
  background-color: ${props => props.backgroundColor || black};
  color: ${props => props.textColor};
  margin-top: -2rem;
`;

const ROOT_C = css`
  color: ${props => props.textColor || white};
  background-color: ${props => props.backgroundColor || black};
  margin-bottom: 4rem;
  margin-top: -2rem;

  ${minWidth.lg`
    margin-bottom: 3rem;
  `}
`;

const ROOT_D = css`
  color: ${props => props.textColor || white};
  background-color: ${props => props.backgroundColor || black};
  margin-bottom: 4rem;
  margin-top: -2rem;
`;

const ROOT_E = css`
  color: ${props => props.textColor || white};
  background-color: ${props => props.backgroundColor || black};
  margin-bottom: 4rem;

  ${minWidth.lg`
    margin-bottom: 3rem;
  `}
`;

const ROOT_F = css`
  color: ${props => props.textColor || white};
  background-color: ${props => props.backgroundColor || black};
  margin-bottom: 4rem;
  margin-top: -2rem;

  ${minWidth.lg`
    margin-bottom: 3rem;
  `}
`;

const CONTAINER_A = css`
  ${container}
  background-color: ${props => props.backgroundColor || black};
  justify-content: space-between;
  padding: 8rem 8rem 0.8rem 8rem;
  margin-top: -2rem;

  ${maxWidth.sm`
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 4.8rem;
    padding-top: ${props =>
      // eslint-disable-next-line no-nested-ternary
      props.isHomePage || props.lowerNav ? '10rem' : '4.8rem'};
    height: max-content;
  `}

  ${minWidth.md`
    padding-top: ${props =>
      // eslint-disable-next-line no-nested-ternary
      !props.isHomePage && props.lowerNav ? '7rem' : '4rem'};
     padding-bottom: 4.8rem;
  `}

  ${minWidth.lg`
    margin-top: -3rem;
    padding-top: ${props => (!props.lowerNav ? '7rem' : '14.8rem')};
  `}
`;

const CONTAINER_C = css`
  ${container}
  padding-top: 8rem;
  padding-bottom: ${props => (!props.hasModule ? '4rem' : '8rem')};

  ${maxWidth.sm`
     margin-top: -2rem;
     padding-top: ${props => (!props.lowerNav ? '4.80rem' : '8rem')};
  `}

  ${minWidth.lg`
    padding-top: 7rem;
  `}
`;

const CONTAINER_D = css`
  ${container}
  padding-top: 8rem;
  padding-bottom: 8rem;

  ${maxWidth.sm`
     margin-top: -2rem;
     padding-top: ${props => (!props.lowerNav ? '4.80rem' : '8rem')};
  `}

  ${minWidth.lg`
    padding-top: 16rem;
    padding-top: 7rem;
    padding-bottom: 8rem;
  `}
`;

const CONTAINER_E = css`
  ${container}
  padding-bottom: 0rem;
`;

const CONTAINER_F = css`
  ${container}
  background-color: ${props => props.backgroundColor || black};
  padding: 4rem;

  ${maxWidth.sm`
     padding: 11.8rem 2.4rem 5rem 2.4rem;
  `}

  ${minWidth.md`
     padding: 11.8rem 4rem 5rem 4rem;
  `}

  ${minWidth.lg`
    ${columnsGutter}

    display: grid;
    grid-template-columns: repeat(12, 1fr);
     align-items: center;
     padding: 10rem 2.4rem 8rem 7.2rem;
  `}
`;

const CONTAINER_G = css`
  ${container}
  padding-top: 8rem;
  padding-bottom: 0rem;

  ${minWidth.lg`
    padding-top: 7rem;
    margin-top: -1rem;
  `}
`;

const CONTAINER_H = css`
  ${container}
  padding-top: 8rem;
  padding-bottom: 8rem;

  ${maxWidth.sm`
    padding-top: 3rem;
    padding-bottom: 4.8rem;
  `}

  ${minWidth.lg`
    padding-top: 7rem;
  `}
`;

const CONTENT_A = css`
  grid-column: 1 / span 6;
  align-items: center;
  justify-content: center;
  ${fadeContentSlideUp.setup}

  &.${ACTIVATE_ANIMATION_CLASS} {
    ${fadeContentSlideUp.play}
  }

  & .content-container {
    margin-left: auto;
  }

  ${minWidth.sm`
    margin-top: 0rem;
    margin-bottom: 3rem;
  `}

  ${minWidth.md`
    margin-top: 3rem;
    width: 65%;
  `}

  ${minWidth.lg`
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    width: 100%;
  `}

  & .cta-wrapper {
    ${maxWidth.sm`
      margin-bottom: 4.8rem;
    `}

    ${minWidth.md`
      margin-bottom: 2.4rem;
    `}
  }

  & .description {
    margin-top: 2.4rem;

    ${minWidth.md`
      font-size: 2rem;
    `}
  }

  & .subtitle {
    font-size: 5rem;
    line-height: 1rem;
    font-weight: lighter;

    ${minWidth.sm`
    font-size: 4rem;
    line-height: 3rem;
  `}

    ${minWidth.md`
      font-size: 4rem;
      line-height: 8rem;
      font-weight: lighter;
    `}

    ${minWidth.lg`
      font-size: 7rem;
      letter-spacing: -0.5rem;
      font-weight: lighter;
      line-height: 8rem;
    `}
  }
`;

const CONTENT_C = css`
  background: none;
  grid-column: 1 / 13;
  align-items: center;
  display: flex;
  justify-content: start;
  margin-top: 6rem;
  margin-bottom: 2rem;

  & .title {
    ${minWidth.sm`
      font-size: 6rem;
      line-height: 5rem;
    `}
    ${minWidth.lg`
      font-size: 9rem;
      line-height: 10rem;
    `}
  }
  }

  & .subtitle {
    font-size: 5rem;
    line-height: 1rem;
    font-weight: lighter;
    ${maxWidth.md`
      font-size: 4rem;
      line-height: 4rem;
    `}
    ${minWidth.lg`
      font-size: 7rem;
      letter-spacing: -0.5rem;
      font-weight: lighter;
      line-height: 8rem;
    `}
  }

  & .content-container {
    padding-bottom: ${spacer4};

    span.eyebrow {
      margin-bottom: ${spacer3};
    }

    ${maxWidth.sm`
      padding-bottom: unset;
      margin-top: -2rem;
    `}

    ${minWidth.md`
      text-align: left;
    `}

    ${minWidth.lg`
      margin-right: 10.5%;
      padding: 0;
      width: 100%;

      p {
        width: 60%;
      }
    `}
  }
`;

const CONTENT_D = css`
  background: none;
  grid-column: 1 / 13;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 6rem;
  margin-bottom: 3rem;

  & .title {
    font-size: 12rem;
  }

  & .subtitle {
    font-size: 5rem;
    line-height: 1rem;
    font-weight: lighter;
    ${maxWidth.md`
      font-size: 4rem;
      line-height: 4rem;
    `}
    ${minWidth.lg`
      font-size: 7rem;
      letter-spacing: -0.5rem;
      font-weight: lighter;
      line-height: 8rem;
    `}
  }

  & .eyebrow {
    margin-bottom: ${spacer2};
  }
  & .content-container {
    padding-bottom: 4rem;


    div.bylineContainer {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ${maxWidth.sm`
      div.bylineContainer {
        display: flex;
        align-items: center;
        justify-content: start;
      }
    `}
    ${maxWidth.md`
      text-align: left;
    `}
    ${minWidth.lg`
      text-align: center;
      padding: 0;
      width: 100%;
    `}
  }
`;

const CONTENT_E = css`
  grid-column: 1 / 13;
  display: flex;
  justify-content: end;
  margin-top: 4rem;

  & .subtitle {
    font-size: 5rem;
    line-height: 1rem;
    font-weight: lighter;
    ${maxWidth.md`
      font-size: 4rem;
      line-height: 4rem;
    `}
    ${minWidth.lg`
      font-size: 7rem;
      letter-spacing: -0.5rem;
      font-weight: lighter;
      line-height: 8rem;
    `}
  }
  & .content-container {
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-bottom: 2rem;

    span.eyebrow {
      margin-bottom: ${spacer3};
    }

    h1.title {
      ${minWidth.lg`
      font-size: 8rem;
      padding-right: ${spacer4};
    `}
      margin-bottom: -${spacer3};
    }

    div.bylineContainer {
      div.byline {
        border-right: 1px solid ${gray};

        ${maxWidth.sm`
          border-right: none;
        `}
      }

      & .publishDate {
        color: ${gray};
      }
    }
    ${maxWidth.md`
      text-align: left;
    `}
    ${minWidth.lg`
      padding: 0;
      width: 100%;
    `}
  }
`;
const CONTENT_F = css`
  margin-bottom: 4.3rem;
  ${minWidth.lg`
    ${columnsGutter}
    display: grid;
    grid-row-start: 1;
    grid-template-columns: repeat(12, 1fr);
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 6;
    grid-row: 1;
    margin-bottom: 0;
  `}

  & .subtitle {
    font-size: 5rem;
    line-height: 1rem;
    font-weight: lighter;

    ${maxWidth.sm`
    font-size: 3rem;
    line-height: 2rem;
  `}

    ${minWidth.md`
      font-size: 2rem;
      line-height: 2rem;
    `}

    ${minWidth.lg`
      font-size: 7rem;
      letter-spacing: -0.5rem;
      font-weight: lighter;
      line-height: 8rem;
    `}
  }
`;

const CONTENT_H = css`
  background: none;
  grid-column: 1 / 13;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: ${spacer2};

  & .subtitle {
    font-size: 5rem;
    line-height: 1rem;
    font-weight: lighter;
    ${maxWidth.md`
      font-size: 4rem;
      line-height: 4rem;
    `}
    ${minWidth.lg`
      font-size: 7rem;
      letter-spacing: -0.5rem;
      font-weight: lighter;
      line-height: 8rem;
    `}
  }

  .cta-wrapper {
    ${maxWidth.sm`
      margin-bottom: 2rem;
    `}

    ${minWidth.md`
      justify-content: start;
    `}

    ${minWidth.lg`
      justify-content: center;
    `}
  }

  .tag-container {
    justify-content: center;

    ${maxWidth.sm`
      justify-content: start;
    `}
  }

  & .eyebrow {
    margin-bottom: ${spacer2};
  }
  & .content-container {

    ${maxWidth.sm`
      margin-top: ${spacer2};
      width: 95%;

       h1, p {
        width: unset;
        text-align: left;
      }
    `}

    ${minWidth.md`
      justify-content: start;
      text-align: left;

      p {
        width: 60%;
      }
    `}

    ${minWidth.lg`
      text-align: center;
      justify-content: center;
      padding: 0;
      width: 50%;

         p {
        width: unset;
      }
    `}
  }
`;

export const FigureContainer = styled.div`
  background-image: url(${props => props.url});
  background-size: cover;
  background-position: center top; /* First value is from left and second is from top. You can use use number as well*/
  background-repeat: no-repeat;
  border-radius: 8px;

  ${fadeContentSlideUp.setup}

  &.${ACTIVATE_ANIMATION_CLASS} {
    ${fadeContentSlideUp.play}
    animation-delay: 1000ms;
  }

  ${props =>
    (props.pageVariation === 'useA' || props.pageVariation === 'useB') &&
    `
  grid-column: 8 / span 7;
  justify-content: end;
  aspect-ratio: 1/1;

  ${minWidth.lg`
      max-height: 632px;
      max-width: 632px;
  `}

  &.${ACTIVATE_ANIMATION_CLASS} {
    ${fadeContentSlideUp.play}
     animation-delay: 1000ms;
  }
  `}

  ${props =>
    (props.pageVariation === 'useE' || props.pageVariation === 'useG') &&
    `
  grid-column: 1 / span 12;
  width: 100vw;
  height: 100vh;
  position: relative;
  background-position: 50% top center;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`}

  ${maxWidth.sm`
     ${props =>
       (props.pageVariation === 'useE' || props.pageVariation === 'useG') &&
       `
      border-radius: 0px;
      height: 50vh;
      `}
  `}
`;

// Style modifier map
const STYLE_MAP = {
  Root: {
    [MODIFIERS.useA]: ROOT_A,
    [MODIFIERS.useB]: ROOT_A,
    [MODIFIERS.useC]: ROOT_C,
    [MODIFIERS.useD]: ROOT_D,
    [MODIFIERS.useE]: ROOT_E,
    [MODIFIERS.useF]: ROOT_B,
    [MODIFIERS.useG]: ROOT_F,
    [MODIFIERS.useH]: ROOT_D,
  },
  Container: {
    [MODIFIERS.useA]: CONTAINER_A,
    [MODIFIERS.useB]: CONTAINER_A,
    [MODIFIERS.useC]: CONTAINER_C,
    [MODIFIERS.useD]: CONTAINER_D,
    [MODIFIERS.useE]: CONTAINER_E,
    [MODIFIERS.useF]: CONTAINER_F,
    [MODIFIERS.useG]: CONTAINER_G,
    [MODIFIERS.useH]: CONTAINER_H,
  },
  Content: {
    [MODIFIERS.useA]: CONTENT_A,
    [MODIFIERS.useB]: CONTENT_A,
    [MODIFIERS.useC]: CONTENT_C,
    [MODIFIERS.useD]: CONTENT_D,
    [MODIFIERS.useE]: CONTENT_E,
    [MODIFIERS.useF]: CONTENT_F,
    [MODIFIERS.useG]: CONTENT_E,
    [MODIFIERS.useH]: CONTENT_H,
  },
};

export const Header = styled.header`
  ${props => props.modifier && getModifierStyles(props, STYLE_MAP.Root)}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${buffer.sm}px;
  position: relative;

  ${minWidth.lg`
    ${columnsGutter}
    ${container}

    display: grid;
    grid-template-columns: repeat(12, 1fr);
  `}

  ${props => props.modifier && getModifierStyles(props, STYLE_MAP.Container)}
`;

export const Grid = styled.div`
  /* This (&&) is to avoid using !important */
  ${minWidth.lg`
    grid-column: 4 / 13;

    && {
      margin-right: -${offset.lg}px;
    }
  `}

  ${minWidth.xl`
    && {
      margin-right: -${offset.xl}px;
    }
  `}
`;

export const Content = styled.div`
  ${props => props.modifier && getModifierStyles(props, STYLE_MAP.Content)}
`;

export const Title = styled(Headline)`
  color: ${props => props.titleColor};
  ${fadeContentSlideUp.setup}

  &.${ACTIVATE_ANIMATION_CLASS} {
    ${fadeContentSlideUp.play}
    animation-delay: 400ms;
  }

  ${minMax(minBreakpoints.lg, minBreakpoints.xl)`
    font-size: 6.4rem;
    line-height: 6.4rem;
  `}
`;

export const Subtitle = styled(Headline)`
  color: ${props => props.titleColor};
  ${minMax(minBreakpoints.lg, minBreakpoints.xl)`
    font-size: 6.4rem;
    line-height: 6.4rem;
  `};
`;

export const Description = styled(Paragraph)`
  color: ${props => props.color};
  margin-top: 1.5rem;
  ${fadeContentSlideUp.setup}

  &.${ACTIVATE_ANIMATION_CLASS} {
    ${fadeContentSlideUp.play}
    animation-delay: 600ms;
  }
`;

export const CtaWrapper = styled.div`
  margin-top: 2.4rem;
  display: flex;
  flex-direction: row;

  ${fadeContentSlideUp.setup}

  &.${ACTIVATE_ANIMATION_CLASS} {
    ${fadeContentSlideUp.play}
    animation-delay: 800ms;
  }


  button {
    margin-right: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${maxWidth.sm`
    &.${ACTIVATE_ANIMATION_CLASS} {
    ${fadeContentSlideUp.play}
    justify-content: start;
  }
  `}
`;

export const Cta = styled(CtaComponent)`
  ${multiple}
  width: max-content;
  align-items: center;
  justify-content: center;
  margin-right: 1.2rem;
`;

export const ContentContainer = styled.div`
  ${fadeContentSlideUp.setup}

  &.${ACTIVATE_ANIMATION_CLASS} {
    ${fadeContentSlideUp.play}
  }
`;
export const eyebrowStyles = css`
  color: ${white}
  font-weight: bolder;
`;

export const BylineContainer = styled.div`
  ${fadeContentSlideUp.setup}

  &.${ACTIVATE_ANIMATION_CLASS} {
    ${fadeContentSlideUp.play}
    animation-delay: 800ms;
  }
`;

export const ModuleContainer = styled.div`
  display: block;
  margin-bottom: 4rem;
  overflow: hidden;
  position: relative;

  ${fadeContentSlideUp.setup}

  &.${ACTIVATE_ANIMATION_CLASS} {
    ${fadeContentSlideUp.play}
    animation-delay: 800ms;
  }

  ${minWidth.md`
    margin-top: -4rem;
    margin-bottom: unset;
  `}

  ${minWidth.lg`
    grid-column: 1 / span 13;
    margin-top: 1rem;
    margin-bottom: 0;
   `}
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: start;
  margin-bottom: ${spacer4};

  ${fadeContentSlideUp.setup}

  &.${ACTIVATE_ANIMATION_CLASS} {
    ${fadeContentSlideUp.play}
    animation-delay: 800ms;
  }

`;

export const HeroVideoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  video {
    width: 85%;
    border-radius: 8px;
    object-fit: cover;
  }
`;

export const VideoContainer = styled.div`
  display: block;
  margin-bottom: 4rem;
  overflow: hidden;
  position: relative;
  ${fadeContentSlideUp.setup}

  &.${ACTIVATE_ANIMATION_CLASS} {
    ${fadeContentSlideUp.play}
    animation-delay: 800ms;
  }

  ${minWidth.lg`
    grid-column: 7 / span 6;
    grid-row-start: 1;
    margin-bottom: 0;
   `}
`;
