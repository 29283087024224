import styled, { css } from 'styled-components';

import {
  Cta,
  DropdownMenu,
  DropdownMenuContainer,
  Icon,
  Tooltip,
  TooltipContainer,
} from 'components/atoms';
import { animations, fontWeights, navHeight } from 'styles/variables';
import { levels } from 'styles/z-index';
import { maxWidth, minWidth } from 'styles/media-queries';
import { container } from 'styles/grid';
import { gray20, solidWhite200 } from '@spotify-internal/encore-web';
import { black, darkGray, white } from 'styles/colors';
import CtaComponent from '../../atoms/cta/Cta';
import { spacer16, spacer24, spacer48 } from '../../../styles/spacers';

/* Transitions, Constants */
const opacityTransition = 'opacity 0.7s ease-in-out';
const opacityTransitionDelay = '0.8s';

const transformTransition = 'transform 1s ease-in-out';
const transformTransitionDelay = '0.5s';

const itemContentDelay = `0.3s`;
const expandCollapseTransition = '0.5s ease-in-out';

const SUB_NAVIGATION_TOP_OFFSET = 1;

/* Dropdown menu children list elements height constants for mobile */
const childrenHeight = 52;

/* Styled Components */
export const Header = styled.header`
  align-items: center;
  background-color: ${props =>
    props.theme.backgroundColor ? props.theme.backgroundColor : black};
  display: flex;
  position: fixed;
  width: 100%;
  z-index: ${levels.persistent};
  -webkit-transition: opacity 200ms, visibility 300ms;
  transition: opacity 200ms, visibility 300ms;

  ${({ theme }) =>
    !theme.showNav &&
    `
      opacity: 0;
      visibility: hidden
    `}

  ${({ theme }) =>
    theme.showNav &&
    `
      opacity: 1;
      visibility: visible;
    `}
`;

export const Container = styled.div`
  ${container}

  background-color:${props =>
    props.theme.backgroundColor ? props.theme.backgroundColor : black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${levels.highlight};
`;

export const Content = styled.div`
  background-color: ${props =>
    props.theme.backgroundColor ? props.theme.backgroundColor : black};
  display: flex;
  height: ${`${navHeight.smToLg}rem`};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: ${levels.highlight};
  span:last-of-type {
    margin-left: auto;
  }

  ${maxWidth.sm`
    height: ${navHeight.mlUp}rem;
      .create-button {
        width: max-content;
        transform: scale(0.9);
        margin-right: -.4rem;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  `}

  ${maxWidth.md`
    height: ${navHeight.mlUp}rem;
  `}

  ${minWidth.lg`
    width: auto;
  `}
`;

export const Logo = styled(Cta).attrs({ type: 'textlink' })`
  display: block;

  path {
    fill: ${props =>
      props.theme === 'Dark' || !props.hasHero
        ? props.theme.highlightColor
        : black};
  }

  /* ReactSVG adds extra div wrapper */
  div,
  svg {
    height: 3.2rem;

    ${minWidth.md`
      height: 3.2rem;
    `}
  }
`;

export const Toggle = styled.button`
  height: 4.4rem;
  position: relative;
  width: 4.4rem;

  /* IE Icon position fix */
  div {
    align-items: center;
    display: flex;
    height: 4.4rem;
    justify-content: center;
    width: 4.4rem;
  }

  svg {
    height: 2.4rem;
    width: 2.4rem;
  }

  .nav-icon {
    position: absolute;
    top: 0;
  }

  .nav-icon-close {
    opacity: 0;
  }

  .nav-icon-hamburger {
    opacity: 1;
    transition: ${opacityTransition};
    transition-delay: ${opacityTransitionDelay};
    color: ${props =>
      props.theme.theme === 'Dark' || !props.theme.hasHero
        ? solidWhite200
        : black};

    div {
      svg {
        path {
          fill: ${props =>
            props.theme.theme === 'Dark' || !props.theme.hasHero
              ? solidWhite200
              : black};
        }
      }
    }
  }

  ${props =>
    props.theme.open &&
    `
    .nav-icon-close {
      opacity: 1;
      transition: ${opacityTransition};
      transition-delay: ${opacityTransitionDelay};
      color: ${
        props.theme.theme === 'Dark' || !props.theme.hasHero
          ? solidWhite200
          : black
      };

       div {
        svg {
          path {
            fill: ${
              props.theme.theme === 'Dark' || !props.theme.hasHero
                ? white
                : black
            };
          }
        }
      }
    }

    .nav-icon-hamburger {
      opacity: 0;
      transition: none;

     div {
       svg {
        path {
         fill: ${props.theme.theme === 'Dark' ? white : black};
        }
      }
     }
    }
  `}
`;

export const NavContainer = styled.div`
  ${container}

  background-color: ${props =>
    props.theme.backgroundColor ? props.theme.backgroundColor : black};
  font-size: 1.4rem;
  height: ${`calc(100vh - ${navHeight.smToLg}rem)`};
  ${props =>
    props.documentHeight
      ? `height: calc(${props.documentHeight}px - ${navHeight.smToLg}rem);`
      : ''};
  left: 0;
  padding-bottom: 4rem;
  padding-top: 3.15rem;
  position: absolute;
  top: ${`${navHeight.smToLg}rem`};
  transform: ${`translateY(calc(-100% - ${navHeight.smToLg}rem))`};
  transition-delay: ${transformTransitionDelay};
  transition: ${transformTransition};
  width: 100%;
  z-index: ${levels.base};

  ${maxWidth.sm`
        padding: ${spacer48} ${spacer24};
        z-index: ${levels.persistent};
   `}

  ${props =>
    props.theme.open &&
    `
      transform: translateY(0);
      transition-delay: 0s;
  `}

  ${props => props.theme.resizing && `transition: none;`}

  ${minWidth.md`
    height: ${`calc(100vh - ${navHeight.mlUp}rem)`};
    padding-top: 3.45rem;
    top: ${navHeight.mlUp}rem;
    transform: translateY(calc(-100% - ${navHeight.mlUp}rem));

    ${props =>
      props.theme.open &&
      `
      transform: translateY(0);
    `}
  `}

  ${minWidth.lg`
    height: ${navHeight.mlUp}rem;
    padding: 0;
    position: relative;
    top: 0;
    transform: translateY(0);
    transition: none;
    left: 2rem;
  `}
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  height: 100%;
  opacity: 0;
  transition: ${opacityTransition};

  ${props =>
    props.theme.open &&
    `
      opacity: 1;
      transition-delay: ${opacityTransitionDelay};
    `}

  ${minWidth.lg`
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    opacity: 1;
    transition: none;
  `}
`;

export const NavElement = styled.div`
  display: flex;
  flex-direction: column;

  ${props =>
    props.scrollable &&
    `
    flex: 1;
    overflow: hidden;
  `}
  ${maxWidth.sm`
  ${props =>
    props.isOpen &&
    `
      padding-top: 2rem;
      .sub-nav-menu-item {
        opacity: 0;
        transform: translateY(-50px);
        animation: slideIn 500ms;
        animation-fill-mode: forwards;
      }

      .sub-nav-menu-item:nth-child(1) {

        animation-delay: 1000ms;
      }

      .sub-nav-menu-item:nth-child(2) {
        animation-delay: 1100ms;
      }

      .sub-nav-menu-item:nth-child(3) {
        animation-delay: 1200ms;
      }

       .sub-nav-menu-item:nth-child(4) {
        animation-delay: 1300ms;
      }

      button {
           opacity: 0;
        transform: translateY(-50px);
        animation: slideIn 500ms;
        animation-fill-mode: forwards;
        animation-delay: 1400ms;
      }
    `}
  `}

  ${maxWidth.sm`
    span:last-of-type{
      margin-top: ${spacer16};
    }
  `}


  ${minWidth.md`
    span:last-of-type {
      margin-left: 0;
    }
  `}

  ${minWidth.lg`
    align-items: center;
    flex-direction: row;
    height: 100%;
    justify-content: flex-start;
    overflow: inherit;
    span:last-of-type {
    margin-right: 2rem;
    margin-left: auto;
  }
  `}

  @keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const NAV_TEXT_LINK = css`
  color: ${props => {
    // eslint-disable-next-line no-nested-ternary
    return props.isCurrentPath
      ? props.theme.highlightColor
      : props.theme.theme === 'Dark' ||
        !props.theme.hasHero ||
        !props.theme.backgroundColor
      ? white
      : black;
  }};
  font-size: 1.6rem;
  font-weight: ${props =>
    props.theme.isLower ? fontWeights.normal_p : fontWeights.extra_bold};

  &:hover,
  &:active {
    text-decoration: underline;
    text-underline-offset: 0.5rem;
    color: ${props => props.theme.highlightColor};
  }
`;

export const NavTextLinkContainer = styled.span`
  display: flex;
  max-width: 40.8rem;
  max-height: ${props =>
    props.subNavFocused ? `${props.listHeight + childrenHeight}px` : '2.4rem'};
  transition: max-height ${expandCollapseTransition} ${itemContentDelay};

  ${minWidth.lg`
    justify-content: center;
    align-items: center;
    height: 100%;
    max-height: 100%;
    margin-bottom: 0;
    margin-right: 2.4rem;
    position: relative;
  `}

  &.lower {
    width: max-content;
    max-width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 1rem;
    margin-bottom: 0;

    ${minWidth.md`
      display: ${props => (props.isBackButton ? 'inline-block' : 'flex')};
      margin-right: 1.6rem;
  `}
  }

  ${props =>
    !props.theme.isLower &&
    props.isMobile &&
    `
    margin-bottom: 4.8rem;
    max-height: 4.8rem;
  `}
`;

export const NavTextLink = styled.a`
  ${NAV_TEXT_LINK}

  ${props =>
    props.theme.isLower &&
    props.isCurrentPath &&
    `
    text-decoration: underline;
    text-underline-offset: 0.5rem;
    color: ${white};
  `}

  ${props =>
    !props.theme.isLower &&
    props.isMobile &&
    `
    font-size: 3.2rem;
    line-height: 3.2rem;
    ${minWidth.md`
      font-size: 4.8rem;
      line-height: 4.8rem;
    `}
  `}
`;

export const SubNavTextLink = styled.a`
  ${NAV_TEXT_LINK}
  color:${white};

  ${props =>
    props.isMobile &&
    `
    font-size: 2.4rem;
    line-height: 2.4rem;
    ${minWidth.md`
      font-size: 3.2rem;
      line-height: 3.2rem;
    `}
  `}

  ${minWidth.lg`
    font-weight: ${fontWeights.bold};
    &:hover {
      color: inherit;
      text-decoration: unset;
    }
  `}
`;

export const NavTextLinkDrop = styled.button`
  ${NAV_TEXT_LINK}
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;

  ${props =>
    props.isMobile &&
    `
    margin-top: 1.6rem;
    font-size: 2.4rem;
    line-height: 2.4rem;
    ${minWidth.md`
      font-size: 3.2rem;
      line-height: 3.2rem;
    `}
  `}
`;

export const Caret = styled(Icon)`
  transition: transform ${animations.defaultTransition};
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div,
  svg {
    path {
      fill: ${props =>
        props.theme.theme === 'Dark' || !props.theme.hasHero ? white : black};
    }
  }

  ${maxWidth.sm`
     svg {
      width: 2rem;
      height: 2rem;
     }
  `}

  ${minWidth.lg`
    display: block;
  `}
`;

const VISIBILITY_TRANSITION = css`
  opacity: ${props => (props.subNavOpen ? `1` : `0`)};
  visibility: ${props => (props.subNavOpen ? `visible` : `hidden`)};
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out,
    visibility 0.5s ease-in-out;
`;

export const SubNavLarge = styled(DropdownMenu)`
  ${VISIBILITY_TRANSITION}

  background-color: ${darkGray};
  margin-top: 2rem;
  transform: ${props =>
    props.subNavOpen ? `translateY(3rem)` : `translateY(0)`};
  top: ${SUB_NAVIGATION_TOP_OFFSET}rem;
  li {
    cursor: pointer;
  }

  &.open {
    .sub-nav-menu-item-dropdown {
      animation: slideIn 400ms;
      animation-fill-mode: both;
    }

    .sub-nav-menu-item-dropdown:nth-child(1) {
      animation-delay: 100ms;
    }

    .sub-nav-menu-item-dropdown:nth-child(2) {
      animation-delay: 200ms;
    }

    .sub-nav-menu-item-dropdown:nth-child(3) {
      animation-delay: 300ms;
    }

    .sub-nav-menu-item-dropdown:nth-child(4) {
      animation-delay: 400ms;
    }

    .sub-nav-menu-item-dropdown:nth-child(5) {
      animation-delay: 500ms;
    }

    svg {
      opacity: 0;
      animation: slideIn 400ms;
      animation-fill-mode: both;
    }
  }

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const SubNav = styled(DropdownMenu)`
  ${VISIBILITY_TRANSITION}

  background-color: ${props => props.theme.backgroundColor || black};
  position: fixed;
  height: calc(100% - ${SUB_NAVIGATION_TOP_OFFSET}rem);
  width: 100vw;
  top: ${SUB_NAVIGATION_TOP_OFFSET}rem;
  transform: ${props =>
    props.subNavOpen ? `translateX(0)` : `translateX(50px)`};
  padding: ${spacer48};

  .subnav-caret {
    justify-content: flex-start;
    div {
      transform: rotate(90deg);
    }
  }

  .sub-nav-menu-item-dropdown:nth-child(1) {
    margin-top: ${spacer24};
  }

  ${props =>
    props.subNavOpen &&
    `
    left: 0;
  `}
`;

export const MobileSubNavContainer = styled(DropdownMenuContainer)`
  ${maxWidth.sm`
    .mobile-nav-caret {
      svg {
        transform: rotate(-90deg);
      }
    }
  `}
`;

export const SubNavContainerLarge = styled.div`
  ${minWidth.lg`
    ${Caret} {
      transform: ${({ subNavFocused }) =>
        `${subNavFocused ? 'rotate(180deg)' : 'unset'}`}
    }
  `}
`;

export const SubNavItem = styled.li`
  padding: ${spacer16} 0;
  ${minWidth.lg`
    padding: 1.5rem 2.4rem 1rem 2.4rem;
    border-radius: 8px;
  `}

  &:hover {
    background-color: ${gray20};
    cursor: pointer;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CtaContainer = styled.span`
  a,
  button {
    height: 4.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  ${minWidth.md`
    margin-right: 0;
  `}

  ${minWidth.lg`
    margin-right: 2rem;
    margin-top: 0;
    max-width: 100%;

    &:last-child {
      margin-right: 0;
    }

    a {
      width: auto;
    }
  `}
`;

export const CreateAdButton = styled(CtaComponent)`
  ${maxWidth.sm`
     padding: 1rem;
     display: flex;
     align-items: center;
     justify-content: center;
  `}

  ${minWidth.lg`
    padding: 1rem 2rem 1rem 2rem;
    font-weight: ${fontWeights.extra_bold}
  `}
`;

// Use for Nav CTA Tooltip
export const NavTooltipContainer = styled(TooltipContainer)`
  display: flex;
  flex-direction: column-reverse;

  ${minWidth.lg`
    display: inline-block;
  `}
`;

// Use for Nav CTA Tooltip
export const NavTooltip = styled(Tooltip)`
  margin-bottom: 2.4rem;

  ${minWidth.md`
    margin-bottom: 3.2rem;
  `}

  ${minWidth.lg`
    margin-bottom: 0;
  `}
`;
export const Backdrop = styled.div`
  background-color: ${black};
  bottom: 0;
  cursor: pointer;
  height: 100vh;
  left: 0;
  opacity: ${props => (props.theme.open ? 1 : 0)};
  pointer-events: ${props => (props.theme.open ? 'all' : 'none')};
  position: absolute;
  right: 0;
  top: 0;
  transition: ${opacityTransition};
  width: 100vw;

  ${minWidth.lg`
    display: none;
    transition: none;
  `}
`;
